import { Box, Flex, NumberInput, Radio, Select } from "@mantine/core";
import React, { useEffect, useState } from "react";
import VariableNumberInput from "../../../GlobalComponent/VariableNumberInput";
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";

const methods = [
  { value: "tap", label: "Tap" },
  // { value: "touchStart", label: "Touch Start" },
  // { value: "touchMove", label: "Touch Move" },
  // { value: "touchEnd", label: "Touch End" },
]

const TapScreen = (props) => {
  const [dataState, setDataState] = useState({
    method: props?.nodeData?.data?.options?.method ?? "tap",
    selectorType: props?.nodeData?.data?.options?.selectorType ?? "selector",
    elementSelect: props?.nodeData?.data?.options?.elementSelect ?? "",
    elementOrder: props?.nodeData?.data?.options?.elementOrder ?? "fixed",
    fixedElement: props?.nodeData?.data?.options?.fixedElement ?? 1,
    randomElementFrom: props?.nodeData?.data?.options?.randomElementFrom ?? 1,
    randomElementTo: props?.nodeData?.data?.options?.randomElementTo ?? 50,
    timeout: 30000,
    x: props?.nodeData?.data?.options?.x ?? 0,
    y: props?.nodeData?.data?.options?.y ?? 0,
  });

  const handleSelector = (type, value) => {
    if (
      typeof value === "string" ||
      typeof value === "number" ||
      typeof value === "boolean"
    ) {
      setDataState({ ...dataState, [type]: value });
    } else {
      setDataState({ ...dataState, [type]: value.target.value });
    }
  };

  useEffect(() => {
    props.handleSetDataBaseModal(dataState);
  }, [props, dataState]);

  return (
    <>
      <Box component="form" mx="auto">
        {/* <Flex
          mih={50}
          gap="md"
          justify="flex-start"
          align="flex-end"
          direction="row"
          wrap="wrap"
        >
          <Select
            value={dataState.method}
            label="Method"
            placeholder="Pick one"
            style={{ width: "150px" }}
            data={methods}
            onChange={(e) => {
              handleSelector("method", e);
            }}
          />
        </Flex> */}

        {/* <Radio.Group
          spacing="sm"
          mt="md"
          name="target-type"
          label="Choose one"
          value={dataState.selectorType}
          onChange={(e) => {
            handleSelector("selectorType", e);
          }}
        >
          <Radio value="selector" label="Selector" />
          <Radio value="coordinates" label="Coordinates" />
        </Radio.Group> */}

        {dataState.selectorType === "selector" && (
          <>
            <VariableSelectWrite
              style={{ marginBottom: "10px" }}
              dataState={dataState.elementSelect}
              label="Select element"
              placeholder="Please enter the element, such as #email input"
              setDataState={handleSelector}
              handleData={(e) => {
                handleSelector("elementSelect", e);
              }}
              handleSelect={(e) => {
                handleSelector("elementSelect", e.newValue);
              }}
            />
            <Flex
              mih={50}
              gap="md"
              justify="flex-start"
              align="flex-start"
              direction="row"
              wrap="wrap"
            >
              <Select
                value={dataState.elementOrder}
                label="Element order"
                placeholder="Pick one"
                style={{ width: "150px" }}
                data={[
                  { value: "fixed", label: "Fixed Value" },
                  { value: "random", label: "Random Value" },
                ]}
                onChange={(e) => {
                  handleSelector("elementOrder", e);
                }}
              />
              {dataState.elementOrder === "fixed" && (
                <VariableNumberInput
                  style={{ width: "200px" }}
                  label=" "
                  dataState={dataState.fixedElement}
                  handleData={(e) => {
                    handleSelector("fixedElement", e);
                  }}
                />
              )}
              {dataState.elementOrder === "random" && (
                <>
                  <NumberInput
                    style={{ width: "150px" }}
                    value={dataState.randomElementFrom}
                    label="from"
                    min={1}
                    onChange={(e) => {
                      handleSelector("randomElementFrom", e);
                    }}
                  />
                  <NumberInput
                    style={{ width: "150px" }}
                    value={dataState.randomElementTo}
                    label="to"
                    min={1}
                    onChange={(e) => {
                      handleSelector("randomElementTo", e);
                    }}
                  />
                </>
              )}
            </Flex>
          </>
        )}

        {dataState.selectorType === "coordinates" && (
          <div>
            <VariableSelectWrite
              label="Enter X coordinate"
              dataState={dataState.x}
              handleData={(e) => {
                handleSelector("x", e);
              }}
              handleSelect={(e) => {
                handleSelector("x", e.newValue);
              }}
            />
            <VariableSelectWrite
              label="Enter Y coordinate"
              dataState={dataState.y}
              handleData={(e) => {
                handleSelector("y", e);
              }}
              handleSelect={(e) => {
                handleSelector("y", e.newValue);
              }}
            />
          </div>
        )}

        <VariableNumberInput
          style={{ marginTop: 8 }}
          label="Timeout waiting (milliseconds)"
          dataState={dataState.timeout}
          handleData={(e) => {
              handleSelector('timeout',e)
          }}
          isMillisecond={true}
        />
      </Box>
    </>
  );
};

export default TapScreen;
